import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import ContentFeed from '../components/content-feed'
import { Container, Row, Col } from 'reactstrap'

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )
    const posts = get(this, 'props.data.allMarkdownRemark.edges')
    const { currentPage, numPages } = this.props.pageContext

    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={`Blog | ` + siteTitle}
        />

        <Container fluid={true} style={{ padding: `20px` }}>
          <ContentFeed
            content={posts}
            currentPage={currentPage}
            numPages={numPages}
            path="blog"
          />
        </Container>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "post" }, draft: { ne: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 480)
          fields {
            slug
          }
          frontmatter {
            date_unformatted: date
            date_formatted: date(formatString: "MMMM DD, YYYY")
            published_unformatted: date
            published_formatted: date(formatString: "MMMM DD, YYYY")
            updated_unformatted: updated
            updated_formatted: updated(formatString: "MMMM DD, YYYY")
            author
            title
            url
            categories
            tags
            featured_image
            draft
          }
        }
      }
    }
  }
`
