import React from 'react'

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <aside
        id="sidebar"
        role="complementary"
        itemScope="itemscope"
        itemType="http://schema.org/WPSideBar"
        style={{
          height: `100%`,
          textAlign: `center`,
        }}
      >
        <div
          style={{
            position: `sticky`,
            top: `40px`,
          }}
        >
          <p />
        </div>
      </aside>
    )
  }
}

export default Sidebar
