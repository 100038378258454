import React from 'react'
import { Link } from 'gatsby'
import { FaGraduationCap, FaBook, FaEnvelope } from 'react-icons/fa'

import { Container, Row, Col } from 'reactstrap'

class StickyHeader extends React.Component {

  constructor(props){
    super(props)
  }

  render(){
      return (
          <div className="d-none d-md-block" style={{
            position: `fixed`,
            zIndex: `100`,
            width: `100%`,
            top: `0`,
            textAlign: `right`,
            padding: `10px 20px 10px 10px`,
            backgroundColor: `#e3ebef`,
            textTransform: `uppercase`,
            fontSize: `1.1em`,
            opacity: `0.9`  
          }}>
            <span style={{fontSize: '0.8em', color: '#fff'}}><FaBook /> <a style={{color: `#fff`, textDecoration: `none !important`}} href="https://www.joshmorony.com/building-mobile-apps-with-ionic-2/?utm_source=navbar&utm_campaign=learn_ionic_two&utm_medium=site">Beginner Ionic Book</a></span>
            <span style={{fontSize: '0.8em', color: '#000', marginLeft: '20px'}}><FaGraduationCap /> <a style={{color: `#000`}} href="https://www.joshmorony.com/elite/?utm_source=navbar&utm_campaign=advancedcourse&utm_medium=site">Advanced Ionic Course</a></span> 
            <span style={{fontSize: '0.8em', color: '#fff', marginLeft: '20px', fontWeight: `bold`}}><FaEnvelope /> <a style={{color: `#fff`}} rel="ck_modal" href="#ck_modal">Subscribe</a></span>
          </div>
      )
  }

}

export default StickyHeader