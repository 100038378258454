import React from 'react'
import { Link } from 'gatsby'

import { Container, Row, Col } from 'reactstrap'

class Footer extends React.Component {

  constructor(props){
    super(props)
  }

  render(){
      return (
          <footer>
              <Container style={{marginTop: `20px`}}>
                <Row>
                    <Col xs="6"><p style={{color: `#a9a7a7`}}>© Mobirony 2019</p></Col>
                    <Col xs="6" style={{textAlign: `right`}}>
                        <a style={{marginRight: `20px`}} href={`/privacy-policy`}>PRIVACY POLICY</a>
                        <a style={{marginRight: `20px`}} href={`/terms-of-service`}>TERMS OF SERVICE</a>
                        <a href={`/contact`}>CONTACT</a>
                    </Col>
                </Row>
              </Container>
          </footer>
      )
  }

}

export default Footer