import React from 'react'
import { Link } from 'gatsby'
import * as _ from 'lodash'

import { Badge } from 'reactstrap';

class TagDisplay extends React.Component {

  constructor(props){
    super(props)
  }

  render(){

    const details = this.props.details;

    if(details.frontmatter.tags != null){

      return (

        <div className="d-none d-md-block" style={this.props.style}>
          {details.frontmatter.tags.map((tag, i) => {
  
                return (
                    <small key={i}>
                      <a href={`/tag/${_.kebabCase(tag)}/`} style={{
                        boxShadow: 'none',
                        fontWeight: 'bold'
                      }}>
                        <Badge color="secondary" style={{
                            padding: `10px`,
                            textTransform: 'uppercase',
                            fontSize: `0.8em`,
                            marginRight: `10px`
                        }}>{tag}</Badge>
                      </a>
                    </small>
                );
              })}          
          </div>
      )

    } else {

      return (
        <div style={this.props.style}>
        </div>
      )

    }

  }

}

export default TagDisplay