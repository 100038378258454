import React from 'react'
import logo from '../assets/logo.png'

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'

class Navigation extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    return (
      <Navbar
        light
        expand="md"
        style={{
          backgroundColor: `#45c5f7`,
          zIndex: `101`,
        }}
      >
        <NavbarBrand
          href="/"
          style={{
            boxShadow: 'none',
            textDecoration: 'none',
            color: 'inherit',
          }}
        >
          <img
            src={logo}
            alt={`logo`}
            style={{
              maxWidth: `200px`,
              height: `auto`,
              marginBottom: 0,
            }}
          />
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink
                style={{
                  color: `#fff`,
                  fontWeight: `bold`,
                  fontSize: `1.1em`,
                  marginLeft: `5px`,
                }}
                href="/contact"
              >
                Contact
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    )
  }
}

export default Navigation
