import React from 'react'

import { StaticQuery, Link } from 'gatsby'
import { graphql } from 'gatsby'

import Navigation from './navigation'
import Search from './search'

import { Container, Row, Badge } from 'reactstrap'

const Header = () => (
  <StaticQuery
    query={graphql`
      query SearchIndexQuery {
        siteSearchIndex {
          index
        }
      }
    `}
    render={data => (
      <header
        id="header"
        role="banner"
        itemScope="itemscope"
        itemType="http://schema.org/WPHeader"
        style={{
          backgroundColor: `#ecf0f1`,
          boxShadow: `0px 0px 2px 1px rgba(0,0,0,0.05)`,
        }}
      >
        <Navigation />

        <Container>
          <Row
            style={{
              margin: `20px 0 0 0`,
            }}
          >
            <div
              style={{
                margin: `0 auto`,
                width: `100%`,
                padding: `1.45rem 1.0875rem`,
                display: `flex`,
                justifyContent: `space-between`,
              }}
            >
              <Search searchIndex={data.siteSearchIndex.index} />
            </div>
          </Row>
        </Container>
      </header>
    )}
  />
)

export default Header
