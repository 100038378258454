import React from 'react'
import { Link } from 'gatsby'
import Header from './header'
import Footer from './footer'
import StickyHeader from './sticky-header'

import Helmet from 'react-helmet'

import { rhythm, scale } from '../utils/typography'
import { css } from 'react-emotion'
import { IoIosArrowDropupCircle } from 'react-icons/io'

const globalStyles = css`
  background-color: #ecf0f1;
  font-family: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
  }

  p {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
      sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-weight: 300;
    line-height: 1.8em;
  }

  @media only screen and (max-width: 600px) {
    .container-fluid {
      padding: 0 !important;
    }

    .col-12 {
      padding: 0;
    }
  }
`

class Layout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <div className={globalStyles}>
        <Helmet />
        <a name="top" />
        <Header />
        {children}
        <Footer />
        <div
          style={{
            position: `fixed`,
            bottom: `5px`,
            right: `20px`,
            fontSize: `50px`,
            opacity: `0.7`,
          }}
        >
          <a title="Back to Top" href="#top" style={{ boxShadow: `none` }}>
            <IoIosArrowDropupCircle />
          </a>
        </div>
      </div>
    )
  }
}

export default Layout
