import React from 'react'
import * as _ from 'lodash'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'

import CategoryDisplay from './category-display'
import TagDisplay from './tag-display'

class BlogItem extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const details = this.props.details

    return (
      <article
        key={details.frontmatter.url}
        style={{
          backgroundColor: `#ffffff`,
          margin: `5px`,
          padding: `40px`,
        }}
      >
        <header itemScope="itemscope" itemType="http://schema.org/WPHeader">
          <a
            style={{ boxShadow: 'none', color: `#000` }}
            href={details.frontmatter.url}
          >
            <h1 itemProp="headline">{details.frontmatter.title}</h1>
          </a>

          <p
            style={{
              textTransform: `uppercase`,
              marginBottom: `10px`,
              opacity: `0.6`,
            }}
          >
            <span
              itemProp="author"
              itemScope="itemscope"
              itemType="http://schema.org/Person"
            >
              <small>
                By
                <a style={{ boxShadow: 'none' }} href={`/#about`}>
                  &nbsp;
                  {details.frontmatter.author}
                </a>
              </small>
            </span>
            <meta
              itemProp="datePublished"
              content={details.frontmatter.published_unformatted}
            />
            <small>
              <time
                itemProp="dateModified"
                dateTime={details.frontmatter.updated_unformatted}
              >
                &nbsp;| Last Updated: {details.frontmatter.updated_formatted}
              </time>
            </small>
          </p>
        </header>

        <div itemProp="text" style={{ clear: `both`, marginTop: `20px` }}>
          <p
            dangerouslySetInnerHTML={{
              __html:
                details.excerpt +
                ' <a href="' +
                details.frontmatter.url +
                '">continue reading.</a>',
            }}
          />
        </div>
      </article>
    )
  }
}

export default BlogItem
