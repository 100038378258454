import React, { Component } from 'react'
import { Link } from 'gatsby'
import { Index } from 'elasticlunr'

import { Form, Input } from 'reactstrap'

import { FaSearch } from 'react-icons/fa'

// Search component
export default class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: ``,
      results: [],
    }
  }

  render() {
    return (
      <div
        style={{
          width: `100%`,
          position: `relative`,
        }}
      >
        <FaSearch
          style={{
            position: `absolute`,
            right: 15,
            top: 15,
          }}
        />
        <Input
          type="text"
          placeholder="Search articles..."
          value={this.state.query}
          onChange={this.search}
          style={{
            width: `100%`,
            height: `45px`,
          }}
        />
        <ul
          style={{
            marginTop: `20px`,
          }}
        >
          {this.state.results
            .sort((a, b) => {
              return new Date(b.updated) - new Date(a.updated)
            })
            .map(page => (
              <li key={page.path}>
                <a href={page.path}>{page.title}</a>
              </li>
            ))}
        </ul>
      </div>
    )
  }
  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.searchIndex)

  search = evt => {
    const query = evt.target.value
    this.index = this.getOrCreateIndex()
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, {
          expand: true /*, 
          fields: {
            tags: {boost: 1}
          }*/,
        })
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    })
  }
}
