import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'
import Sidebar from './sidebar'

import BlogItem from './blog-item'
import { rhythm } from '../utils/typography'

class ContentFeed extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const content = this.props.content
    const path = this.props.path
    const currentPage = this.props.currentPage
    const numPages = this.props.numPages
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage =
      currentPage - 1 === 1
        ? `/${path}`
        : `/${path}/page/` + (currentPage - 1).toString()
    const nextPage = `/${path}/page/` + (currentPage + 1).toString()

    return [
      <Row style={{ marginRight: `auto`, marginLeft: `auto` }}>
        <Col xs="12" lg="9">
          <div>
            {content.map(({ node }) => {
              return (
                <div
                  key={node.frontmatter.url}
                  style={{
                    marginBottom: `40px`,
                  }}
                >
                  <BlogItem details={node} />
                </div>
              )
            })}
          </div>
        </Col>
        <Col lg="3" className="d-none d-lg-block">
          <Sidebar />
        </Col>
      </Row>,

      <Row style={{ marginRight: `auto`, marginLeft: `auto` }}>
        <Col xs="12">
          <div
            style={{
              display: `inline-block`,
              textAlign: `center`,
              width: `100%`,
              margin: `20px 0`,
            }}
          >
            <small
              style={{
                margin: `10px`,
              }}
            >
              Page {currentPage} of {numPages}
            </small>
            <ul
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                listStyle: 'none',
                padding: 0,
              }}
            >
              {!isFirst && (
                <li
                  style={{
                    margin: `3px`,
                    backgroundColor: `#fff`,
                    padding: `10px`,
                    fontSize: `0.8em`,
                  }}
                >
                  <a href={prevPage} rel="prev">
                    ←
                  </a>
                </li>
              )}
              {Array.from({ length: numPages }, (_, i) => (
                <li
                  key={`pagination-number${i + 1}`}
                  style={{
                    margin: `3px`,
                    backgroundColor: `#fff`,
                    padding: `10px`,
                    fontSize: `0.8em`,
                  }}
                >
                  <a
                    href={`/${path}${i === 0 ? '' : '/page/' + (i + 1)}`}
                    style={{
                      padding: rhythm(1 / 4),
                      textDecoration: 'none',
                      color: i + 1 === currentPage ? '#ffffff' : '',
                      background: i + 1 === currentPage ? '#cecece' : '',
                    }}
                  >
                    {i + 1}
                  </a>
                </li>
              ))}
              {!isLast && (
                <li
                  style={{
                    margin: `3px`,
                    backgroundColor: `#fff`,
                    padding: `10px`,
                    fontSize: `0.8em`,
                  }}
                >
                  <a href={nextPage} rel="next">
                    →
                  </a>
                </li>
              )}
            </ul>
          </div>
        </Col>
      </Row>,
    ]
  }
}

export default ContentFeed
