import React from 'react'
import { Link } from 'gatsby'
import * as _ from 'lodash'

class CategoryDisplay extends React.Component {

  constructor(props){
    super(props)
  }

  render(){

    const details = this.props.details;

      return (

        <div className="categories" style={this.props.style}>
          {details.frontmatter.categories.map((category, i) => {


                let separator = (details.frontmatter.categories.length == i + 1) ? '' : ' | ';
                let color = '#000';

                switch(category){
                  case 'Angular Tutorials':
                    color = '#e6373f'
                    break;
                  case 'Ionic Tutorials':
                    color = '#408bff'
                    break;
                  default:
                    color = '#000'
                }

                return (
                    <small key={i}>
                      <a href={`/category/${_.kebabCase(category)}/`} style={{
                        boxShadow: 'none',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        color: color,
                      }}>
                        {category} <span style={{color: `#cecece`, fontWeight: `normal`}}>{separator}</span>
                      </a>
                    </small>
                );
              })}          
          </div>
      )
  }

}

export default CategoryDisplay